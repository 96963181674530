"use client";

// Libraries
import { Link } from "@/lib/i18n";
import Button from "../Button/Button";
import { useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { AnimatePresence, motion } from "framer-motion";

// Component
import Logo from "../Logo/Logo";

export default function Header(props) {
    // Variable
    const { data: session, status } = useSession();

    const loading = status === "loading";

    // States
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [panelIsOpen, setPanelIsOpen] = useState(false);

    // Variables
    let links = (
        <>
            {/* <li className="mr-5">
                <Link
                    href="/entrepreneurs"
                    className={`${
                        props.currentPage &&
                        props.currentPage == "entrepreneurs"
                            ? "text-primary"
                            : "text-black dark:text-white"
                    } hover:text-primary-300 dark:hover:text-primary`}
                >
                    <div className="flex items-center gap-3">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 md:hidden block"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                            />
                        </svg>
                        Entrepreneurs
                    </div>
                </Link>
            </li> */}
            {/* <li className="mr-5">
                <Link
                    href="/developers"
                    className={`${
                        props.currentPage && props.currentPage == "developers"
                            ? "text-primary"
                            : "text-black dark:text-white"
                    } hover:text-primary-300 dark:hover:text-primary`}
                >
                    <div className="flex items-center gap-3">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 md:hidden block"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5"
                            />
                        </svg>
                        Développeurs
                    </div>
                </Link>
            </li> */}
            {/* <li className="mr-5">
                <Link
                    href="/pricing"
                    className={`${
                        props.currentPage && props.currentPage == "pricing"
                            ? "text-primary"
                            : "text-black dark:text-white"
                    } hover:text-primary-300 dark:hover:text-primary`}
                >
                    <div className="flex items-center gap-3">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 md:hidden block"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                            />
                        </svg>
                        Tarifs
                    </div>
                </Link>
            </li> */}
            <li className="md:inline-block hidden">
                <div
                    className="text-black hover:text-excitationRed dark:text-white dark:hover:text-gray-300 flex items-center gap-1 cursor-pointer"
                    onClick={() => setPanelIsOpen((prev) => !prev)}
                >
                    Ressources
                    <motion.div
                        animate={{
                            rotate: panelIsOpen ? -180 : 0,
                            type: "spring",
                        }}
                        transition={{ duration: 0.09 }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                fillRule="evenodd"
                                d="M4.43 8.512a.75.75 0 0 1 1.058-.081L12 14.012l6.512-5.581a.75.75 0 0 1 .976 1.138l-7 6a.75.75 0 0 1-.976 0l-7-6a.75.75 0 0 1-.081-1.057"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </motion.div>
                </div>

                {/* Panel */}
                <AnimatePresence>
                    {panelIsOpen && (
                        <motion.div
                            variants={{
                                hidden: { opacity: 0, y: 15 },
                                visible: { opacity: 1, y: 0 },
                            }}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            className="absolute bg-white dark:bg-bgDark-550 rounded-xl shadow-lg overflow-hidden top-14 right-5 flex gap-5 divide-x dark:divide-borderDark"
                        >
                            <div className="p-5 w-[300px]">
                                <h3 className="font-bold text-3xl dark:text-white">
                                    Ressources
                                </h3>
                                <div className="text-sm mt-1 dark:text-lightBlue">
                                    Découvrez toutes nos ressources pour vous
                                    aider à réaliser vos projets.
                                </div>
                            </div>
                            <div className="lg:w-[450px] w-[400px]">
                                <ul className="flex flex-col">
                                    <li className="inline-block mr-5 w-full hover:bg-primary hover:bg-opacity-5 duration-150 px-5 py-3 group">
                                        <Link href="/articles">
                                            <div
                                                className={`${
                                                    props.currentPage &&
                                                    props.currentPage ==
                                                        "articles"
                                                        ? "text-primary font-bold"
                                                        : "text-black group-hover:text-excitationRed dark:text-white dark:group-hover:text-primary"
                                                } flex items-center gap-1`}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        fillRule="evenodd"
                                                        d="M8.512 4.43a.75.75 0 0 1 1.057.082l6 7a.75.75 0 0 1 0 .976l-6 7a.75.75 0 0 1-1.138-.976L14.012 12L8.431 5.488a.75.75 0 0 1 .08-1.057"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                                Articles
                                            </div>
                                            <div className="text-sm text-gray-500 dark:text-lightBlue">
                                                Découvrez nos centaines
                                                d'articles et filtrez votre
                                                recherche par catégorie.
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="inline-block mr-5 w-full hover:bg-primary hover:bg-opacity-5 duration-150 px-5 py-3 group">
                                        <Link href="/courses">
                                            <div
                                                className={`${
                                                    props.currentPage &&
                                                    props.currentPage ==
                                                        "courses"
                                                        ? "text-primary font-bold"
                                                        : "text-black group-hover:text-excitationRed dark:text-white dark:group-hover:text-primary"
                                                } flex items-center gap-1`}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        fillRule="evenodd"
                                                        d="M8.512 4.43a.75.75 0 0 1 1.057.082l6 7a.75.75 0 0 1 0 .976l-6 7a.75.75 0 0 1-1.138-.976L14.012 12L8.431 5.488a.75.75 0 0 1 .08-1.057"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                                Formations
                                            </div>

                                            <div className="text-sm text-gray-500 dark:text-lightBlue">
                                                Formez-vous en autodidacte grâce
                                                à nos formations à la carte 100%
                                                en ligne, enseignées par des
                                                personnes dévouées parmi les
                                                plus récompensées et primées au
                                                monde.
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="inline-block mr-5 w-full hover:bg-primary hover:bg-opacity-5 duration-150 px-5 py-3 group">
                                        <Link href="/incubator">
                                            <div
                                                className={`${
                                                    props.currentPage &&
                                                    props.currentPage ==
                                                        "incubator"
                                                        ? "text-primary font-bold"
                                                        : "text-black group-hover:text-excitationRed dark:text-white dark:group-hover:text-primary"
                                                } flex items-center gap-1`}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        fillRule="evenodd"
                                                        d="M8.512 4.43a.75.75 0 0 1 1.057.082l6 7a.75.75 0 0 1 0 .976l-6 7a.75.75 0 0 1-1.138-.976L14.012 12L8.431 5.488a.75.75 0 0 1 .08-1.057"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                                Incubateur
                                            </div>

                                            <div className="text-sm text-gray-500 dark:text-lightBlue">
                                                Rencontrez notre incubateur
                                                d'excellence dédié à catalyser
                                                le potentiel des projets
                                                innovants qui ont le pouvoir de
                                                transformer le monde de demain.
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="inline-block mr-5 w-full hover:bg-primary hover:bg-opacity-5 duration-150 px-5 py-3 group">
                                        <Link href="/accelerators">
                                            <div
                                                className={`${
                                                    props.currentPage &&
                                                    props.currentPage ==
                                                        "accelerators"
                                                        ? "text-primary font-bold"
                                                        : "text-black group-hover:text-excitationRed dark:text-white dark:group-hover:text-primary"
                                                } flex items-center gap-1`}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        fillRule="evenodd"
                                                        d="M8.512 4.43a.75.75 0 0 1 1.057.082l6 7a.75.75 0 0 1 0 .976l-6 7a.75.75 0 0 1-1.138-.976L14.012 12L8.431 5.488a.75.75 0 0 1 .08-1.057"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                                Accélérateurs
                                            </div>

                                            <div className="text-sm text-gray-500 dark:text-lightBlue">
                                                De quelques semaines à plusieurs
                                                mois, nos accélérateurs vous
                                                permettent de vous lancer dans
                                                votre projet avec le meilleur
                                                des accompagnements.
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="inline-block mr-5 w-full hover:bg-primary hover:bg-opacity-5 duration-150 px-5 py-3 group">
                                        <Link href="/mentoring">
                                            <div
                                                className={`${
                                                    props.currentPage &&
                                                    props.currentPage ==
                                                        "accelerators"
                                                        ? "text-primary font-bold"
                                                        : "text-black group-hover:text-excitationRed dark:text-white dark:group-hover:text-primary"
                                                } flex items-center gap-1`}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        fillRule="evenodd"
                                                        d="M8.512 4.43a.75.75 0 0 1 1.057.082l6 7a.75.75 0 0 1 0 .976l-6 7a.75.75 0 0 1-1.138-.976L14.012 12L8.431 5.488a.75.75 0 0 1 .08-1.057"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                                Mentors
                                            </div>

                                            <div className="text-sm text-gray-500 dark:text-lightBlue">
                                                Offrez-vous les conseils d'un
                                                expert sélectionné par Believemy
                                                pour vous aider à avancer dans
                                                votre projet.
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </li>
            <li className="md:inline-block hidden ml-5 mr-5">
                <Link href="/courses" className="text-black dark:text-white">
                    Formations
                </Link>
            </li>
            <div className="md:hidden flex flex-col gap-5">
                <li className="mr-5">
                    <Link
                        href="/articles"
                        className={`${
                            props.currentPage && props.currentPage == "articles"
                                ? "text-primary"
                                : "text-black dark:text-white"
                        } hover:text-primary-300 dark:hover:text-primary`}
                    >
                        <div className="flex items-center gap-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
                                />
                            </svg>
                            Articles
                        </div>
                    </Link>
                </li>
                <li className="mr-5">
                    <Link
                        href="/courses"
                        className={`${
                            props.currentPage && props.currentPage == "courses"
                                ? "text-primary"
                                : "text-black dark:text-white"
                        } hover:text-primary-300 dark:hover:text-primary`}
                    >
                        <div className="flex items-center gap-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                                />
                            </svg>
                            Formations
                        </div>
                    </Link>
                </li>
                <li className="mr-5">
                    <Link
                        href="/incubator"
                        className={`${
                            props.currentPage &&
                            props.currentPage == "incubator"
                                ? "text-primary"
                                : "text-black dark:text-white"
                        } hover:text-primary-300 dark:hover:text-primary`}
                    >
                        <div className="flex items-center gap-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    fill="currentColor"
                                    fillRule="evenodd"
                                    d="M12.669 1.51a1.962 1.962 0 0 1 2.182.155c1.848 1.385 3.9 3.78 3.9 7.142c0 2.732-1.196 4.506-2.722 5.57c-1.487 1.036-3.247 1.373-4.429 1.373a6.594 6.594 0 0 1-3.967-1.402c-1.356-1.08-2.383-2.85-2.383-5.54c0-2.424 1.406-3.982 2.594-4.778c.92-.618 2.292-.216 2.472 1.04c.065.46.302.787.472.898c.02.013.034.02.045.025a.498.498 0 0 0 .095-.116c.482-.757.675-1.838.675-2.488c0-.82.433-1.517 1.066-1.879Zm-1.856 4.496l.005-.002l-.005.002Zm.036-.008a.01.01 0 0 1 .003.001h-.003Zm2.563-3.184a.647.647 0 0 0-.309.575c0 .835-.228 2.223-.91 3.294c-.248.388-.598.687-1.049.784c-.447.096-.86-.036-1.176-.243c-.61-.398-1.025-1.156-1.137-1.941a.106.106 0 0 0-.007-.027L8.82 5.25s-.002-.003-.01-.006c-.013-.005-.061-.016-.132.032c-.917.615-1.929 1.76-1.929 3.531c0 2.263.84 3.59 1.817 4.368A5.097 5.097 0 0 0 11.6 14.25c.952 0 2.392-.282 3.572-1.104c1.14-.794 2.078-2.117 2.078-4.339c0-2.7-1.646-4.703-3.299-5.942c-.2-.15-.387-.138-.54-.051Zm1.804 6.468a.75.75 0 0 1 .503.934c-.27.896-1.384 2.534-3.719 2.534a.75.75 0 0 1 0-1.5c1.506 0 2.15-1.028 2.282-1.466a.75.75 0 0 1 .934-.502ZM3.313 14.699a.75.75 0 0 1 .988-.386l5 2.187a.75.75 0 1 1-.601 1.375l-5-2.188a.75.75 0 0 1-.387-.988Zm17.374 0a.75.75 0 0 1-.386.988l-16 7a.75.75 0 0 1-.601-1.374l16-7a.75.75 0 0 1 .987.386Zm-6.874 4.594a.75.75 0 0 1 .988-.386l5.5 2.406a.75.75 0 1 1-.601 1.374l-5.5-2.406a.75.75 0 0 1-.387-.988Z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            Incubateur
                        </div>
                    </Link>
                </li>
                <li className="mr-5">
                    <Link
                        href="/accelerators"
                        className={`${
                            props.currentPage &&
                            props.currentPage == "accelerators"
                                ? "text-primary"
                                : "text-black dark:text-white"
                        } hover:text-primary-300 dark:hover:text-primary`}
                    >
                        <div className="flex items-center gap-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeWidth="1.5"
                                    d="m15.578 3.382l2 1.05c2.151 1.129 3.227 1.693 3.825 2.708C22 8.154 22 9.417 22 11.94v.117c0 2.525 0 3.788-.597 4.802c-.598 1.015-1.674 1.58-3.825 2.709l-2 1.049C13.822 21.539 12.944 22 12 22s-1.822-.46-3.578-1.382l-2-1.05c-2.151-1.129-3.227-1.693-3.825-2.708C2 15.846 2 14.583 2 12.06v-.117c0-2.525 0-3.788.597-4.802c.598-1.015 1.674-1.58 3.825-2.708l2-1.05C10.178 2.461 11.056 2 12 2s1.822.46 3.578 1.382ZM21 7.5l-4 2M12 12L3 7.5m9 4.5v9.5m0-9.5l4.5-2.25l.5-.25m0 0V13m0-3.5l-9.5-5"
                                ></path>
                            </svg>
                            Accélérateurs
                        </div>
                    </Link>
                </li>
                <li className="mr-5">
                    <Link
                        href="/mentoring"
                        className={`${
                            props.currentPage && props.currentPage == "mentors"
                                ? "text-primary"
                                : "text-black dark:text-white"
                        } hover:text-primary-300 dark:hover:text-primary`}
                    >
                        <div className="flex items-center gap-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                                />
                            </svg>
                            Mentors
                        </div>
                    </Link>
                </li>
            </div>
        </>
    );

    // Methods
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // if (session) {
    //     links = (
    //         <>
    //             <li className="mr-5">
    //                 <Link
    //                     href="/"
    //                     className={`${
    //                         props.currentPage &&
    //                         props.currentPage === "dashboard" &&
    //                         "text-black dark:text-white"
    //                     }`}
    //                 >
    //                     Tableau de bord
    //                 </Link>
    //             </li>
    //             <li className="mr-5">
    //                 <Link
    //                     href="/courses"
    //                     className={`${
    //                         props.currentPage &&
    //                         props.currentPage === "courses" &&
    //                         "text-black dark:text-white"
    //                     }`}
    //                 >
    //                     Formations
    //                 </Link>
    //             </li>
    //             <li className="mr-5">
    //                 <Link
    //                     href="/articles"
    //                     className={`${
    //                         props.currentPage &&
    //                         props.currentPage === "articles" &&
    //                         "text-black dark:text-white"
    //                     }`}
    //                 >
    //                     Articles
    //                 </Link>
    //             </li>
    //             {session && session.user && session.user.pseudo ? (
    //                 <li className="mr-5">
    //                     <Link
    //                         href={`/u/${session.user.pseudo}`}
    //                         className={`${
    //                             props.currentPage &&
    //                             props.currentPage === "profile" &&
    //                             "text-black dark:text-white"
    //                         }`}
    //                     >
    //                         Profil
    //                     </Link>
    //                 </li>
    //             ) : (
    //                 <li className="mr-5">
    //                     <Link
    //                         href={`/u/believer-${session.user.userId}`}
    //                         className={`${
    //                             props.currentPage &&
    //                             props.currentPage === "profile" &&
    //                             "text-black dark:text-white"
    //                         }`}
    //                     >
    //                         Profil
    //                     </Link>
    //                 </li>
    //             )}
    //             <li className="mr-5">
    //                 <Link
    //                     href="/community"
    //                     className={`${
    //                         props.currentPage &&
    //                         props.currentPage === "community" &&
    //                         "text-white"
    //                     }`}
    //                 >
    //                     Communauté
    //                 </Link>
    //             </li>
    //             {/* <li
    //                 className={
    //                     session &&
    //                     session.user &&
    //                     !session.user.pro &&
    //                     !session.user.ultimate &&
    //                     "mr-5"
    //                 }
    //             >
    //                 <Link href="/community">
    //                     <a
    //                         className={`${
    //                             props.currentPage &&
    //                             props.currentPage === "community" &&
    //                             "text-white"
    //                         }`}
    //                     >
    //                         Mentors
    //                     </a>
    //                 </Link>
    //             </li> */}
    //             {session &&
    //                 session.user &&
    //                 !session.user.pro &&
    //                 !session.user.ultimate && (
    //                     <li>
    //                         <Link
    //                             href="/pricing"
    //                             className={`${
    //                                 props.currentPage &&
    //                                 props.currentPage === "pricing" &&
    //                                 "text-white"
    //                             }`}
    //                         >
    //                             Premium
    //                         </Link>
    //                     </li>
    //                 )}
    //         </>
    //     );
    // }

    let headerClasses = `${
        session && session.user
            ? "bg-white dark:bg-bgDark-700 border-b border-transparent dark:border-borderDark"
            : "bg-transparent"
    } px-3 py-3 z-50`;

    if (props.blackHeader) {
        headerClasses = `bg-transparent ${
            props.gradients && "bg-gradient-header"
        } dark:bg-bgDark-700 dark:border-borderDark px-3 py-5 dark z-50`;
    }

    if (props.absoluteHeader) {
        headerClasses = `${
            isScrolled
                ? props.blackHeader
                    ? "background-blur-dark"
                    : "background-blur-white"
                : "bg-transparent"
        } px-3 py-5 z-50 fixed left-0 top-0 right-0 duration-150 ${
            props.blackHeader && "dark"
        }`;
    }

    useEffect(() => {
        // Detect the actual scroll position on page load
        if (window.scrollY > 0) {
            setIsScrolled(true);
        }

        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            setIsScrolled(false);
        }
    }, [isOpen, isScrolled]);

    return (
        <header className={headerClasses}>
            <div
                className={`${
                    props.useSpecialContainer
                        ? props.useSpecialContainer
                        : "lg:container mx-auto"
                } flex justify-between items-center relative`}
            >
                <div className="flex items-center gap-3">
                    {/* Mobile */}
                    <button
                        className="inline-flex hover:bg-excitationOrange text-excitationOrange p-1 rounded md:hidden dark:text-white ml-auto hover:text-white outline-none"
                        onClick={toggleMenu}
                        aria-label="Menu"
                    >
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>

                    {/* Logo */}
                    <div className="flex items-center lg:justify-start gap-5">
                        <div>
                            <Link href="/">
                                <div className="sm:block hidden">
                                    <Logo
                                        white={
                                            (session &&
                                                session.user &&
                                                session.user.darkMode) ||
                                            props.blackHeader
                                        }
                                        // priority={props.priority || false}
                                    />
                                </div>
                                <div className="sm:hidden block">
                                    <Logo
                                        white={
                                            (session &&
                                                session.user &&
                                                session.user.darkMode) ||
                                            props.blackHeader
                                        }
                                        icon
                                        // priority={props.priority || false}
                                    />
                                </div>
                            </Link>
                        </div>
                        {/* {props.blackHeader || (!session && !loading) ? (
                            <div className="italic font-sourceSerif dark:text-white lg:block hidden">
                                For Believers.
                            </div>
                        ) : (
                            <div className="italic font-sourceSerif text-black dark:text-white lg:block hidden">
                                For Believers.
                            </div>
                        )} */}
                    </div>
                </div>
                {!props.pure && (
                    <>
                        {/* Profile For Mobile */}
                        <div className="md:hidden block">
                            {session && (
                                <Link href="/">
                                    <Button small>Tableau de bord</Button>
                                </Link>
                            )}
                            {!session && (
                                // <Link href="/login">
                                //     <div className="hidden dark:block">
                                //         <Button small>Connexion</Button>
                                //     </div>
                                //     <div className="block dark:hidden">
                                //         <Button small>Connexion</Button>
                                //     </div>
                                // </Link>
                                <div className="flex items-center gap-5 md:pl-5">
                                    <Link href="/login">Connexion</Link>
                                    <Link href="/signup">
                                        <div className="block dark:hidden">
                                            <Button small>Commencer</Button>
                                        </div>
                                    </Link>
                                </div>
                            )}
                        </div>

                        {/* Menu (computer) */}
                        <nav className="md:block hidden relative">
                            <div className="flex items-center">
                                <ul className="flex">{links}</ul>
                                {session ? (
                                    // <ProfileDropdown />
                                    <Link href="/">
                                        <Button small>Tableau de bord</Button>
                                    </Link>
                                ) : (
                                    // <Link href="/login">
                                    //     <Button small>Connexion</Button>
                                    // </Link>
                                    <div className="flex items-center gap-5 md:pl-5">
                                        <Link
                                            href="/login"
                                            className="dark:text-white"
                                        >
                                            Connexion
                                        </Link>
                                        <Link href="/signup">
                                            <Button small>Commencer</Button>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </nav>
                    </>
                )}
            </div>
            {isOpen && (
                <nav className="md:hidden block border-b border-box-outline pb-4 fixed top-0 bottom-0 left-0 right-0 text-3xl bg-gray-100 dark:bg-box p-10 z-50">
                    <div className="flex justify-end text-excitationOrange hover:text-excitationRed">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-10 h-10 cursor-pointer"
                            onClick={toggleMenu}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </div>
                    <ul className="flex flex-col gap-5">{links}</ul>
                </nav>
            )}
        </header>
    );
}
